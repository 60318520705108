import {
  auditTime,
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  fromEvent,
  map,
  Observable,
  pairwise,
  share,
  startWith,
  Subscription,
  withLatestFrom
} from "rxjs"
import isBrowser from "~/utils/is-browser"
import { createProviderWithInit } from "~/utils/provider-factory"
import type { AppSettings, AppSettingsColors } from "~/types"
import settings from "~/settings"
import RangeSubject from "~/utils/RangeSubject"
import chroma from "chroma-js"
import { ValueObservable } from "~/utils/ValueSubject"
import { exportGlobalStyle } from "~/components/GlobalStyles"
import { createGlobalStyle, css } from "styled-components"

type AppEnv = typeof process.env

const MEDIA = "(prefers-color-scheme: dark)"
const THEMES = settings.colors

console.log("THEMES", THEMES)

const THEME_CSS_VAR_PREFIX = `theme`
const DEFAULT_THEME = 'light'

function buildColorThemeVariables(colorThemes: AppSettingsColors) {
  const chunks: string[] = [`:root {`]
  chunks.push(`--${THEME_CSS_VAR_PREFIX}-background: var(--${THEME_CSS_VAR_PREFIX}-${DEFAULT_THEME}-background);`)
  chunks.push(`--${THEME_CSS_VAR_PREFIX}-foreground: var(--${THEME_CSS_VAR_PREFIX}-${DEFAULT_THEME}-foreground);`)
  chunks.push(`}`)
  for (const key in colorThemes) {
    const theme = colorThemes[key]
    const lines: string[] = [`:root.${key} {`]
    lines.push(`--${THEME_CSS_VAR_PREFIX}-background: var(--${THEME_CSS_VAR_PREFIX}-${key}-background, ${theme.background});`)
    lines.push(`--${THEME_CSS_VAR_PREFIX}-foreground: var(--${THEME_CSS_VAR_PREFIX}-${key}-foreground, ${theme.foreground});`)
    lines.push(`}`)
    chunks.push(lines.join(''))
  }
  return chunks.join('\n')
}

exportGlobalStyle(createGlobalStyle`${buildColorThemeVariables(THEMES)}`)

const STORAGE_THEME_KEY = 'app:theme'

export class Storage {
  static read = (key: string) => {
    if (!isBrowser()) return
    return localStorage.getItem(key)
  }

  static write = (key: string, value: string) => {
    if (!isBrowser()) return
    localStorage.setItem(key, value)
  }

  private intKeysMap = new Map<string, BehaviorSubject<number>>()
  private strKeysMap = new Map<string, BehaviorSubject<string>>()
  private sub = new Subscription()

  public unsubscribeAll = this.sub.unsubscribe.bind(this.sub)

  public readNumber(keyName: string, fallbackValue?: number) {
    const rawValue = Storage.read(keyName)
    if (!rawValue) return fallbackValue
    try {
      return parseFloat(rawValue)
    } catch {
      return fallbackValue
    }
  }

  public readString(keyName: string, fallbackValue?: string) {
    const rawValue = Storage.read(keyName)
    if (!rawValue) return fallbackValue
    return rawValue
  }

  public numberKey(keyName: string, initialValue?: number) {
    let key$ = this.intKeysMap.get(keyName)
    if (!key$) {
      key$ = new BehaviorSubject<number|undefined>(initialValue) 
      this.sub.add(key$.pipe(auditTime(300), distinctUntilChanged()).subscribe(value => Storage.write(keyName, value.toString())))
      this.intKeysMap.set(keyName, key$)
    }
    return key$
  }

  public stringKey(keyName: string, initialValue?: string) {
    let key$ = this.strKeysMap.get(keyName)
    if (!key$) {
      key$ = new BehaviorSubject<string|undefined>(initialValue) 
      this.sub.add(key$.pipe(auditTime(300), distinctUntilChanged()).subscribe(value => Storage.write(keyName, value)))
      this.strKeysMap.set(keyName, key$)
    }
    return key$
  }
}

export class AppService {
  public storage = new Storage()
  public systemTheme$ = new Observable<string>(subscriber => {
    if (!isBrowser()) return subscriber.next("light")
    const media = window.matchMedia(MEDIA)
    subscriber.next(media.matches ? "dark" : "light")
    media.addListener((e: MediaQueryListEvent | MediaQueryList) => {
      subscriber.next(e.matches ? "dark" : "light")
    })
  })
  public userTheme$ = new BehaviorSubject<string>(this.storage.readString(STORAGE_THEME_KEY, 'system'))
  public resolvedTheme$ = new ValueObservable<string>(
    combineLatest([this.userTheme$, this.systemTheme$]).pipe(
      distinctUntilChanged(),
      map(([ut, st]) => (ut === "system" ? st : ut))
    )
  )
  public pageWidth$ = new BehaviorSubject<number>(isBrowser() ? window.innerWidth : 720)
  public pageHeight$ = new BehaviorSubject<number>(isBrowser() ? window.innerHeight : 720)
  public isDark$ = new ValueObservable<boolean>(this.resolvedTheme$.pipe(map(t => t === "dark" || t === "night")))

  public init() {
    if (!isBrowser()) return
    this.pageWidth$.next(window.innerWidth)
    this.pageHeight$.next(window.innerHeight)
  }

  private handleThemeSwitch([prevTheme, currentTheme]: [string, string]) {
    if (!isBrowser()) return
    console.log("handleThemeSwitch", prevTheme, "=>", currentTheme)
    const d = document.documentElement
    if (prevTheme) {
      d.classList.remove(prevTheme)
    }
    if (currentTheme) {
      d.classList.add(currentTheme)
      d.setAttribute("data-theme", currentTheme)
      d.style.colorScheme = currentTheme
    } else {
      d.removeAttribute("data-theme")
      d.style.colorScheme = null
    }
  }

  constructor(
    public settings: AppSettings,
    public env: AppEnv = process.env
  ) {
    if (!isBrowser()) return

    const windowResize$ = fromEvent(window, "resize").pipe(auditTime(10), share())

    windowResize$
      .pipe(
        map(ev => (ev.target as Window).innerWidth),
        distinctUntilChanged()
      )
      .subscribe(this.pageWidth$)

    windowResize$
      .pipe(
        map(ev => (ev.target as Window).innerHeight),
        distinctUntilChanged()
      )
      .subscribe(this.pageHeight$)

    this.resolvedTheme$.pipe(startWith(""), pairwise()).subscribe(this.handleThemeSwitch)
    this.userTheme$.subscribe(this.storage.stringKey(STORAGE_THEME_KEY))
    this.userTheme$.next(this.userTheme$.value)
  }
}

const tuple = createProviderWithInit<AppService>((app: AppService) => {
  return () => {
    app.init()
  }
})

export const AppProvider = tuple[0]
export const useApp = tuple[1]

export default AppProvider
