import { Observable, Subject, tap } from "rxjs"

export class ValueSubject<T> extends Subject<T> {
  value?: T
  constructor() {
    super()
    this.subscribe(v => (this.value = v))
  }
}

export class ValueObservable<T> extends Subject<T> {
  value?: T
  constructor(public value$: Observable<T>) {
    super()
    this.value$.subscribe((...args) => {
      this.value = args[0]
      this.next(args[0])
    })
  }
}

export default ValueSubject
